import React, { useState } from "react"
import { graphql } from "gatsby"
import Container, { Col } from "../components/Container"
import Layout from "../components/Layout"
import Video from "../components/Video"
import TrackShare from "../components/TrackShare"
import styled from "@emotion/styled"
import tw from "twin.macro"
import withLocation from "../utils/withLocation"
import queryString from "query-string"
import { ReactSVG } from "react-svg"
import PageLink from "../components/PageLink"
import MD from "../utils/MD"
import { getFeature } from "../models/feature"
import ActiveTrackProvider from "../contexts/ActiveTrackContext"
import SharedTrackProvider from "../contexts/SharedTrackContext"
import TrackDetails from "../components/TrackDetails"
import { Helmet } from "react-helmet"
import FeatureCards from "../components/FeatureCards"
import features from "../staticQueries/features"

type Props = {
  data: {
    feature: {
      edges: string[]
    }
  }
  location: {
    search: string
  }
}

const FeaturePage: React.FC<Props> = ({ data, location }) => {
  const feature = getFeature(data.feature.edges[0])
  const playQuery = queryString.parse(location.search).play
  const [featurePlayStatus, setFeaturePlayStatus] = useState<string>(playQuery == "true" ? "play" : "pause")

  const featureCards = features().filter(obj => {
    return obj.id !== feature.id
  })

  console.log(featurePlayStatus)

  return (
    <StyledLayout>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/wavesurfer.js/3.3.1/wavesurfer.js"></script>
      </Helmet>
      <ActiveTrackProvider>
        <SharedTrackProvider>
          <TrackShare />
          <Container className="feature-content" tw="pt-8 pb-32">
            <Col>
              <Video
                src={feature.video}
                setFeaturePlayStatus={setFeaturePlayStatus}
                featurePlayStatus={featurePlayStatus}
              />
              <div className="meta" tw="pt-3">
                <div className="client-badge" tw="flex items-center">
                  <ReactSVG src={feature.logo} tw="opacity-30" />
                  <h1 tw="text-2xl ml-4 mb-2">{feature.title}</h1>
                </div>
                <div className="categories" tw="hidden xs:block xs:-mt-16">
                  <dl tw="flex w-full justify-end text-sm mb-0">
                    <dt tw="block">Genres</dt>
                    {feature.genres.map(genre => (
                      <dd key={genre.slug} tw="ml-5 mb-3">
                        <PageLink to={`/library/genre/${genre.slug}`}>{genre.name}</PageLink>
                      </dd>
                    ))}
                  </dl>
                  <dl tw="flex w-full justify-end text-sm mb-0">
                    <dt>Vibes</dt>
                    {feature.vibes.map(vibe => (
                      <dd key={vibe.slug} tw="ml-5">
                        <PageLink to={`/library/vibe/${vibe.slug}`}>{vibe.name}</PageLink>
                      </dd>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="description" tw="max-w-3xl pb-6">
                <MD content={feature.description} externalLinks />
              </div>
              {feature.track && (
                <>
                  <h4 tw="mb-0 text-lg font-normal">Track in this feature</h4>
                  <div className="feature-track">
                    <TrackDetails track={feature.track[0]} setFeaturePlayStatus={setFeaturePlayStatus} />
                  </div>
                </>
              )}
              {feature.similar.length && <h4 tw="text-lg font-normal mt-8 mb-0">Similar Tracks</h4>}
              {feature.similar.map(track => (
                <div key={track.id} className="feature-track">
                  <TrackDetails track={track} setFeaturePlayStatus={setFeaturePlayStatus} />
                </div>
              ))}
            </Col>
          </Container>
          <FeatureCards features={featureCards} />
        </SharedTrackProvider>
      </ActiveTrackProvider>
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  ${tw``}
  .client-badge svg path {
    fill: #111;
  }
  .feature-track {
    ${tw`mb-4 mt-2`}
    .launcher {
      top: 0;
      position: relative;
    }
  }
  .feature-content {
    .categories a {
      border-bottom: 1px solid #aaa;
    }
    .description {
      p {
        ${tw`text-xl`}
      }
    }
  }
`
export default withLocation(FeaturePage)

export const pageQuery = graphql`
  query FeatureQuery($id: String!) {
    feature: allAirtable(filter: { table: { eq: "Features" }, id: { eq: $id } }) {
      edges {
        node {
          id
          data {
            Feature_Name
            Feature_Image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Feature_SVG {
              localFiles {
                publicURL
              }
            }
            Similar_Tracks {
              data {
                Track_Title
                URL
              }
              id
            }
            Feature_Track {
              data {
                Track_Title

                URL
                Vibes {
                  data {
                    Vibe_Name
                  }
                }
                Genres {
                  data {
                    Genre_Name
                  }
                }
                Energy {
                  data {
                    Energy_Name
                  }
                }
              }
              id
            }
            Feature_Color
            Feature_Description
            Feature_Video
          }
        }
      }
    }
  }
`
