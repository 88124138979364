import React, { useContext, useRef } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import ReactPlayer from "react-player"
import { ActiveTrackContext, ActiveTrackContextType, versionDefault } from "../contexts/ActiveTrackContext"

interface Props {
  src: string
  featurePlayStatus: string
  setFeaturePlayStatus: Function
}

const Video: React.FC<Props> = ({ src, featurePlayStatus, setFeaturePlayStatus }) => {
  const { updateActiveTrack } = useContext(ActiveTrackContext) as ActiveTrackContextType
  const handleOnEnded = () => {
    setFeaturePlayStatus("pause")
    if (player && player.current) {
      player.current.seekTo(0)
    }
  }

  const handleOnReady = () => {
    if (featurePlayStatus == "play") {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search.slice(1))
      params.delete("play")
      window.history.replaceState({}, "", `${window.location.pathname}${window.location.hash}`)
    }
  }

  const handleOnPlay = () => {
    setFeaturePlayStatus("play")
    if (window.player) {
      window.player.destroy()
    }
    updateActiveTrack({
      id: "",
      version: versionDefault,
    })
  }

  const player = useRef<ReactPlayer>(null)

  return (
    <StyledVideo>
      <div className="video-container" tw="relative overflow-hidden">
        <div tw="absolute inset-0">
          <ReactPlayer
            playing={featurePlayStatus == "play" ? true : false}
            ref={player}
            url={src}
            width="100%"
            height="100%"
            controls={true}
            onPlay={handleOnPlay}
            onEnded={handleOnEnded}
            onReady={handleOnReady}
          />
        </div>
      </div>
    </StyledVideo>
  )
}

const StyledVideo = styled.div`
  ${tw``}
  .video-container {
    padding-top: 56.25%;
  }
`
export default Video
